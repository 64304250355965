import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Close from "@material-ui/icons/Close";

import {
  companyStructureOptions,
  companyTypeOptions,
} from "../../../../commons/loan-application-constants";
import OptionsGroup from "../../components/OptionsGroup";
import Button from "../../components/Button";
import { useStyles as useSectionStyles } from "../../../../utils/loan-application.styles";
import { primaryColor } from "../../../../scss/colors.scss";

const fields = ["jobTitle", "startDate", "companyName", "companyAddress"];

const useStyles = makeStyles({
  datePickerWrapper: {
    marginLeft: "4px",
    "& .MuiInputLabel-formControl": {
      marginLeft: "8px",
    },
  },
  radioGroup: {
    "& .MuiIconButton-colorSecondary": {
      color: primaryColor,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
});

const WorkDetailsStep = ({
  formik,
  index,
  handlePrevStep,
  handleNextStep,
  isEditing,
  handleDeleteEmployment,
}) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();
  const formikErrors = formik.errors?.income?.items?.at?.(index) || {};
  const formikTouched = formik.touched?.income?.items?.at?.(index) || {};
  const formikValues = formik.values?.income?.items?.at?.(index);
  const renderedFields = fields.concat(
    ...(formikValues?.employmentType !== "self-employed"
      ? ["companyType"]
      : ["companyStructure"])
  );

  let displayStartDateError = "";
  if (formikTouched.startDate && formikErrors.startDate === "Required") {
    displayStartDateError = formikErrors.startDate;
  } else if (formikTouched.startDate && formikErrors.startDate) {
    displayStartDateError = "Invalid Date";
  }

  const onNextStep = () => {
    renderedFields.forEach((field) => {
      formik.setFieldTouched(`income.items[${index}].${field}`);
    });

    if (
      renderedFields.some(
        (field) => formik.errors?.income?.items?.at(index)?.[field]
      )
    ) {
      return;
    }

    handleNextStep();
  };

  useEffect(() => {
    if (
      formikValues?.employmentType === "unemployed" ||
      formikValues?.employmentType === "retired"
    ) {
      onNextStep();
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" gridRowGap={48}>
      <Grid container xs={12} sm={10} md={6} spacing={6}>
        <Grid item xs={12} sm={7}>
          <TextField
            name={`income.items[${index}].jobTitle`}
            onChange={formik.handleChange}
            value={formik.values.income.items[index].jobTitle}
            onBlur={formik.handleBlur}
            variant="outlined"
            label="Job Title"
            fullWidth
            placeholder="Whilst typing"
            InputLabelProps={{
              shrink: true,
              color: "primary",
            }}
            error={formikTouched.jobTitle && Boolean(formikErrors.jobTitle)}
            helperText={formikTouched.jobTitle && formikErrors.jobTitle}
            FormHelperTextProps={{
              style: { position: "absolute", bottom: "-20px" },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box classes={{ root: classes.datePickerWrapper }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth
                variant="outlined"
                format="dd/MM/yy"
                placeholder="dd/mm/yy"
                label="Start Date"
                InputLabelProps={{
                  shrink: true,
                  color: "primary",
                }}
                onChange={(value) =>
                  formik.setFieldValue(
                    `income.items[${index}].startDate`,
                    value
                  )
                }
                value={formik.values.income.items[index].startDate}
                error={
                  formikTouched.startDate && Boolean(formikErrors.startDate)
                }
                helperText={displayStartDateError}
                FormHelperTextProps={{
                  style: {
                    position: "absolute",
                    bottom: "-24px",
                    left: "8px",
                    zIndex: -1,
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            variant="outlined"
            label="Company Name"
            fullWidth
            placeholder="Type to search for company"
            InputLabelProps={{
              shrink: true,
              color: "primary",
            }}
            name={`income.items[${index}].companyName`}
            onChange={formik.handleChange}
            value={formik.values.income.items[index].companyName}
            onBlur={formik.handleBlur}
            error={
              formikTouched.companyName && Boolean(formikErrors.companyName)
            }
            helperText={formikTouched.companyName && formikErrors.companyName}
            FormHelperTextProps={{
              style: { position: "absolute", bottom: "-20px" },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            variant="outlined"
            label="Company Address"
            fullWidth
            placeholder="Add company address"
            InputLabelProps={{
              shrink: true,
              color: "primary",
            }}
            name={`income.items[${index}].companyAddress`}
            onChange={formik.handleChange}
            value={formik.values.income.items[index].companyAddress}
            onBlur={formik.handleBlur}
            error={
              formikTouched.companyAddress &&
              Boolean(formikErrors.companyAddress)
            }
            helperText={
              formikTouched.companyAddress && formikErrors.companyAddress
            }
            FormHelperTextProps={{
              style: { position: "absolute", bottom: "-20px" },
            }}
          />
        </Grid>
        {formik.values.income.items[index].employmentType !==
        "self-employed" ? (
          <Grid item xs={12}>
            <FormLabel component="legend">Type of company</FormLabel>
            <RadioGroup
              row
              value={formik.values.income.items[index].companyType}
              onChange={formik.handleChange}
              name={`income.items[${index}].companyType`}
              classes={{ root: classes.radioGroup }}
            >
              {companyTypeOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  control={<Radio />}
                  labelPlacement="start"
                />
              ))}
            </RadioGroup>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <OptionsGroup
              title="Company Structure"
              options={companyStructureOptions}
              selectedValue={formik.values.income.items[index].companyStructure}
              onSelect={(value) =>
                formik.setFieldValue(
                  `income.items[${index}].companyStructure`,
                  value
                )
              }
              error={
                formikTouched.companyStructure &&
                Boolean(formikErrors.companyStructure)
              }
              helperText={
                formikTouched.companyStructure && formikErrors.companyStructure
              }
            />
          </Grid>
        )}
      </Grid>
      {isEditing && (
        <Grid container>
          <Button
            endIcon={<Close />}
            onClick={() => handleDeleteEmployment(index)}
            customColor="danger"
          >
            Delete this Employment
          </Button>
        </Grid>
      )}
      <Grid
        container
        xs={12}
        md={6}
        justifyContent="space-between"
        classes={{ root: sectionClasses.navigationButtonsContainer }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>
        <Button endIcon={<NavigateNextIcon />} onClick={onNextStep}>
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default WorkDetailsStep;
